import { getCulcPrice, getOperRozpElement } from "../Axios/calculationsYr";

export const rozpChange = (value, SetCalcList) => {
    console.log(value);
    parseInt(value) && getOperRozpElement(value).then((response)=>{
        let urls = response.calculatorPriceYurs;
        let requests = urls.map(url => getCulcPrice(url));
        let calcListAll = [];

        Promise.all(requests)
        .then((responses) =>{responses.forEach(response => {
            calcListAll.push(response);
        })
            SetCalcList(calcListAll);
        });})
}