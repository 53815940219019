import React from 'react'
import Row from 'react-bootstrap/esm/Row'
import { Link } from 'react-router-dom'

const ComTarifPlansItem = ({nameTariff, isOsr, payment, nameCom, url}) => {

  return (
    <Row className={'tarif-plans short'}>
        <Link className="tarif-plans__inner" to={url} target={'_blank'}>
            <p className="tarif-plans__define">Комерційна пропозиція</p>
            <p className="tarif-plans__name">{nameTariff}</p>
            {isOsr
                ? <p className="tarif-plans__osr active">З тарифом ОСР</p>
                : <p className="tarif-plans__osr active">Без тарифу ОСР</p>
            }
            <div className="tarif-plans__price">
                { (payment === 1 || payment === 4) && <><p>Оплата по факту</p> <img width={'100%'} src="/img/tarif-comer1.svg" alt=""/> </> }
                { payment === 2 && <><p>Оплата плановими платежами</p> <img width={'100%'} src="/img/tarif-comer2.svg" alt=""/> </>}
                { payment === 3 && <><p>Попередня оплата</p> <img width={'100%'} src="/img/tarif-comer3.svg" alt=""/> </>}
            </div>
            <p className="tarif-plans__offer-type">{nameCom}</p>
            <img src="/img/tarif-comer-download.svg" className="download" alt="download"/>
        </Link>      
    </Row>
  )
}

export default ComTarifPlansItem